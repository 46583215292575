<template>

  <!-- RESULTS -->
  <div>
    <div class='section'>
      <div class='container is-max-desktop'>      
        


        <!-- INTRO -->
        <div v-if='!showDetailedResults'>
          <div class='block'>
            <h3 class='is-size-3 has-text-600 has-text-info has-text-centered'>Your Section-Level {{ contentAreaText }} Professional Learning Results</h3>
          </div>

          <div class='block content'>
            <p>Thank you for completing Rivet Education’s Professional Learning Diagnostic. Below you will see a summary of your responses for each of the six sections of the diagnostic, and a color-coded rating for each.</p>
            <ul>
              <li><span class='has-text-weight-bold has-text-red'>Red</span>: While you may have some best practices in place, there are some characteristics of strong professional learning that are missing or underdeveloped.</li>
              <li><span class='has-text-weight-bold has-text-yellow'>Yellow</span>: Your professional learning reflects some characteristics; however, there is room for growth.</li>
              <li><span class='has-text-weight-bold has-text-green'>Green</span>: You have structures and systems in place that reflect the characteristics of strong professional learning.</li>
            </ul>
            <p>Each section summary will detail why this section is important, why you received this color, and some potential next steps and resources.</p>
            <br>
          </div>
        </div>
      
        <div v-else>
          <div class='block'>
            <h3 class='is-size-3 has-text-600 has-text-info has-text-centered'>Your Detailed {{ contentAreaText }} Professional Learning Results</h3>
          </div>

          <div class='block content'>
            <p>This page provides tailored feedback for each of your question responses, in addition to the six section summaries. You can also learn more about <span><a href="#marketing">how Rivet Education can support you</a></span> while you develop your professional learning strategies.</p>
            <br>
          </div>
        </div>

        


        <!-- DOMAIN BREAKDOWNS -->
        <div v-for="(domain, d) in userScores" :key='"dd"+d' class='block pb-5'>
        

          <!-- DOMAIN-LEVEL -->
          <div class='block'>
            <h3 class='is-size-3 has-text-600 has-text-info'>{{ domain.domainTitle }}</h3>
          </div>
          
          <div class='box'>
            <div class='columns'>
              <div class='column is-1'>
                <!-- <div class='block'> -->
                  <div class='block has-text-centered'>
                    <font-awesome-icon icon="circle-dot" class='colorIcon has-text-centered' :style='{color: domain.colorCode}'/>
                  </div>

                <!-- </div> -->
              </div>
            
              <div class='column'>
                <div class='block'>
                  <h4 class='domain-msg' v-html='domain.msg' />
                </div>
              </div>
            </div>
            
            
            <!-- QUESTION-LEVEL -->
            <div class='block' v-if='showDetailedResults' >

              <!-- visual spacer -->
              <hr>
              
              <div v-for="(question, q) in domain.questions" :key="'qq'+q" class='block'>
                <p class='has-text-600'><span>{{ question.text}}</span></p>
                <!-- <p class='has-text-600'><span :style="{color: domain.colorCode}">{{ question.val }}</span></p> -->
                <div class='content pb-3'><span v-html='question.advice' /></div>
              </div>


              <!-- DEBUG TABLE -->
              <table class='table' v-show='false'>
                <thead>
                  <tr>
                    <td>id</td>
                    <td>you put</td>
                    <td>points</td>
                    <td>are you auto red?</td>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(question2, q) in domain.questions" :key="'qq'+q">
                    <td>{{question2.qId}}</td>
                    <td>{{question2.val}}</td>
                    <td>{{question2.pts}}</td>
                    <td>{{question2.aRed}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          

          </div>
        </div>



        <!-- SUBMIT BUTTON -->
        <div class='block has-text-centered' v-if='!showDetailedResults'>
          <h3 class='is-size-3 has-text-600 has-text-info'>Want to receive detailed feedback and tailored recommendations to improve your professional learning?</h3>

          <div class='columns is-centered'>
            <div class='column is-8'>
              <div class='block content'>
                <p>If you provide your email, we can provide you with your question-level results. We will also email you a link so that you can access your results later.</p>
              </div>
            </div>
          </div>

          <div class='block'>
            <button class='button is-large is-danger' @click='showModal=true'>Get started</button>
          </div>

        </div>


        <!-- SUBMIT MODAL -->        
        <div class="modal" :class="{'is-active': showModal}">
          <div class="modal-background"></div>
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title has-text-info has-text-600">Get your detailed results</p>
              <button class="delete" aria-label="close" @click='showModal=false'></button>
            </header>
            <section class="modal-card-body">
              
              <div class="field">
                <label class="label">Email</label>
                <div class="control">
                  <input class="input" 
                         :class="{'is-danger': !isValidEmail, 'is-success': isValidEmail}" 
                         type="email" 
                         placeholder="your email" 
                         v-model="userEnteredEmail">
                </div>
              </div>

<!-- 
              <div class="field">
                <label class="label">Role</label>
                <div class="control">
                  <div class="select"
                      :class="{'is-danger': !isValidRole, 'is-success': isValidRole}" >
                    <select v-model='userEnteredRole'>
                      <option disabled value="">Please select your role </option>
                      <option v-for='(opt, o) in options' :key="'o'+o">{{ opt }}</option>
                    </select>
                  </div>
                </div>
              </div>
 -->

              <div class="field checkbox-container">
                <div class="control">
                  <label class="checkbox">
                    <input type="checkbox" v-model='acceptsTerms'>
                    I understand that by providing my email, Rivet Education may contact me about other updates and services.
                  </label>
                </div>
              </div>
            </section>

            <footer class="modal-card-foot">
              <div class="field is-grouped">
                <div class="control">
                  <button class="button"
                          :class="{'is-info': readyToSubmit, 'is-gray': !readyToSubmit}" 
                          :disabled="!readyToSubmit"
                          @click="sendEmail">
                    Submit
                  </button>
                </div>
              </div>


            </footer>
          </div>
        </div>



        <!-- MARKETING -->
        <div v-if="showDetailedResults" class='block pb-5'>
          <a name="marketing" />
          <!-- title -->
          
          <div class='box box-blue'>
            <div class='block'>
              <h3 class='is-size-3 has-text-600 has-text-white has-text-centered'>Do you want additional support?</h3>
            </div>

            <div class='block content has-text-white'>
                <p><a href='https://riveteducation.org/' target="_blank" class='has-text-white'>Rivet Education</a> is a nationally-recognized expert in professional learning. We work with state and local education leaders to:</p>
                <ul>
                  <li>Develop academic strategies to scale educators' access to high-quality instructional materials and curriculum-based professional learning;</li>
                  <li>Provide them with the support and knowledge needed to plan and execute high-quality professional learning;</li>
                  <li>Build their expertise in procuring, selecting, and managing vendors that support their implementation of high-quality instructional materials; and</li>
                  <li>Diagnose and measure the quality of curriculum implementation in schools and districts.</li>
                </ul>
            </div> 

            <div class='block content has-text-white'>
              <p>Email us at info@riveteducation.org to get started.</p>
            </div>
          </div>   
        </div>


      </div>
    </div>
  
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  // the first time this loads, figure out if you need to update the route
  mounted() {
    this.updateRoute();
  },
  data() {
    return {
      showModal: false,
      userEnteredEmail: null,
      acceptsTerms: false,
      // userEnteredRole: null,
      // options: [
      //   'Classroom teacher',
      //   'Principal/assistant principal',
      //   'Instructional coach',
      //   'School system leader',
      //   'Other school-based employee',
      //   'Other school system employee',
      //   'SEA employee',
      //   'Other',
      // ]
    }
  },
  computed: {
    ...mapState(['currentResponses', 'uuid']),
    ...mapGetters(['userScores', 'hasEmail', 'contentAreaText']),
    crWatcher() {
      return this.currentResponses
    },
    isValidEmail() {
     if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.userEnteredEmail)) {
      return true;
     } else {
      return false;
     }
    },
    // isValidRole() {
    //   return this.userEnteredRole !== null
    // },
    readyToSubmit() {
      return this.isValidEmail && this.acceptsTerms //&& this.userEnteredRole 
    },
    showDetailedResults() {
      return this.hasEmail
    }
  },
  watch: {
    // when the route changes, update route
    '$route' () {
      this.updateRoute();
    },
    crWatcher() {
      if (this.currentResponses === undefined) {
        this.$router.push({ 
          name: "fourOhFour"
        })        
      }
    }
  },
  methods: {
    ...mapActions(['getResponse', 'submitEmail']),
    updateRoute() {
      const id = this.$route.params.id;
      console.log(this.uuid)
      console.log(id)
      if (this.uuid === id) {
        console.log('use the data that the user already entered')
      } else {
        console.log('hit the db to get data')
        this.getResponse(id);
      }
    },
    sendEmail() {
      this.showModal = false;
      this.submitEmail({
        'email': this.userEnteredEmail, 
        // 'role': this.userEnteredRole
      });
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
